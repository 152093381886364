import React from 'react';
import { Col, Row } from 'antd';
import { ApiHelpers } from '../helpers';

const MenuItem = (props) => {
    const { menuItem, showModal } = props;

    return (
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 11, offset: 0 }} xl={{ span: 11, offset: 0 }} xxl={{ span: 11, offset: 0 }}>
            <Row className="menuItemContainer" gutter={[10, 20]} onClick={() => showModal(menuItem)}>
                <Col span={14}>
                    <h3>{menuItem.title}</h3>
                    <p className="description">{menuItem.itemDescription}</p>
                    <p className="ingredients">{menuItem.ingredients}</p>
                    <p className="price">{menuItem.price} lei {menuItem.weight ? <span>({menuItem.weight})</span> : null}</p>
                </Col>
                <Col span={10}>
                    {menuItem.itemImagePath ? <img src={ApiHelpers.getImageLink(menuItem.itemImagePath)} alt={menuItem.title} title={menuItem.title} className="menuItemImage" /> : null}
                </Col>
            </Row>
        </Col>
    )
}

export default MenuItem;