import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'antd';
import { ApiHelpers } from '../helpers';
import { useTranslation } from 'react-i18next';
import iconAppStore from '../assets/img/appStore.svg';
import iconPlayStore from '../assets/img/playStore.png';
import { BellOutlined, StarOutlined, CheckCircleOutlined, FileTextOutlined } from '@ant-design/icons';

const DrawerWelcome = (props) => {
    const { t } = useTranslation();
    const { restaurantDetails, handleClickStore, textSize, decoded, onClose, type } = props;

    return (
        <>
            <Row justify="left">
                <Col>
                    {restaurantDetails.subscriptionPlan === 'Full' ? (
                        <>
                            <h2 className={textSize === 'small' ? 'small' : null}>
                                {restaurantDetails.sysRestaurantType !== 'SelfService'
                                    ? t('ID_POFTIGO_DOWNLOAD_APP_TEXT')
                                    : t('ID_POFTIGO_DOWNLOAD_APP_TEXT_SELF1')}
                            </h2>
                            <div className="storeIconContainer">
                                <a
                                    href="https://apps.apple.com/us/app/poftigo/id1574336637"
                                    target="blank"
                                    className="storeIcon"
                                    onClick={() => handleClickStore('apple')}>
                                    <img title="Pofigo in App Store" src={iconAppStore} alt="Pofigo in App Store" />
                                </a>
                                sau
                                <a
                                    href="https://play.google.com/store/apps/details?id=ro.gourmettech.poftigo"
                                    target="blank"
                                    onClick={() => handleClickStore('google')}>
                                    <img alt="Get it on Google Play" title="Poftigo in Google Play" src={iconPlayStore} height={60} />
                                </a>
                            </div>
                            {restaurantDetails.sysRestaurantType === 'SelfService' ? (
                                <h2 className={textSize === 'small' ? 'small' : null}>{t('ID_POFTIGO_DOWNLOAD_APP_TEXT_SELF2')}</h2>
                            ) : null}
                        </>
                    ) : restaurantDetails.subscriptionPlan === 'Free' ? (
                        <Row justify="space-between" gutter={20}>
                            <Col>
                                <Button type="primary" size="large" onClick={onClose} className="buttonModalClose" icon={<FileTextOutlined />}>
                                    {t('ID_POFTIGO_BROWSE_MENU')}
                                </Button>
                            </Col>
                        </Row>
                    ) : null}
                </Col>
            </Row>
            {type !== 'menuItem' ? (
                <>
                    <Row>
                        <Col>
                            {restaurantDetails.subscriptionPlan === 'Full' && restaurantDetails.sysRestaurantType !== 'SelfService' ? (
                                <Button type="primary" size="large" onClick={onClose} className="buttonModalClose">
                                    {t('ID_POFTIGO_POPUP_DISMISS_BUTTON')}
                                </Button>
                            ) : null}
                        </Col>
                    </Row>

                    <div className={restaurantDetails.subscriptionPlan !== 'Free' ? 'drawerFooterRestaurant bordered' : 'drawerFooterRestaurant'}>
                        <Row gutter={20} align="middle">
                            <Col>
                                {restaurantDetails.logoPath ? (
                                    <img
                                        src={ApiHelpers.getImageLink(restaurantDetails.logoPath)}
                                        title={restaurantDetails.restaurantName}
                                        alt={restaurantDetails.restaurantName}
                                        className="logoImagePublicDrawer"
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <Row justify="space-between">
                                    <Col>
                                        <h3>{restaurantDetails.restaurantName}</h3>
                                    </Col>
                                </Row>
                                {restaurantDetails.sysRestaurantType !== 'SelfService' ? (
                                    <Row>
                                        <Col>
                                            {t('ID_POFTIGO_TABLE_LABEL')} {decoded.TableIndex}
                                        </Col>
                                    </Row>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default DrawerWelcome;
