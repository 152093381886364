import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Input, message } from 'antd';
import { ApiHelpers } from '../helpers';
import { useTranslation } from 'react-i18next';
import { firebaseConfig } from '../config';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const { TextArea } = Input;

const CallWaiterMessage = (props) => {
    const { t } = useTranslation();
    const {
        restaurantDetails,
        isModalCallWaiterVisible,
        decoded,
        storageWaiterCalled,
        storageWaiterCalledTimestamp,
        setIsWaiterCalled,
        setWaiterCalledTimestamp,
        setCallWaiterLoading,
        onClose,
    } = props;
    const INITIAL_MESSAGE_MODEL = {
        tableSessionToken: '',
        uuid: '',
        restaurantToken: restaurantDetails.uniqueIdentificationToken,
        message: '',
    };
    const [feedbackContent, setFeedbackContent] = useState({ ...INITIAL_MESSAGE_MODEL });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setFeedbackContent({ ...INITIAL_MESSAGE_MODEL });
    }, [isModalCallWaiterVisible]);

    const onSubmit = () => {
        setIsLoading(true);
        ApiHelpers.callWaiter(decoded.RestaurantToken, decoded.TableCode, feedbackContent.message).then(
            (response) => {
                if (response.status === 200) {
                    localStorage.setItem(storageWaiterCalled, JSON.stringify(true));
                    localStorage.setItem(storageWaiterCalledTimestamp, JSON.stringify(new Date().getTime()));
                    setIsWaiterCalled(true);
                    // Log event in Analytics
                    logEvent(analytics, 'select_content', {
                        content_type: 'button',
                        item_id: 'callWaiter',
                    });
                    setWaiterCalledTimestamp(new Date());
                    setCallWaiterLoading(false);
                    setIsLoading(false);
                    onClose();
                    message.success(t('ID_POFTIGO_NOTIFICATION_CALL_WAITER_SUCCESS'));
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    return (
        <>
            {restaurantDetails.subscriptionPlan === 'Full' || restaurantDetails.subscriptionPlan === 'Appetizer' ? (
                <>
                    <Row className="feedback-container">
                        <Col span={18}>
                            <Input
                                placeholder={t('ID_POFTIGO_CALL_WAITER_TEXT')}
                                className="comments-box waiter-message"
                                onChange={(e) => {
                                    setFeedbackContent({ ...feedbackContent, message: e.target.value });
                                }}
                                value={feedbackContent.message}
                            />
                        </Col>

                        <Col span={6}>
                            <Button type="primary" size="large" onClick={onSubmit} className="buttonModalClose buttonInline" loading={isLoading}>
                                {t('ID_POFTIGO_SUBMIT_CALL_WAITER')}
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : null}
        </>
    );
};

export default CallWaiterMessage;
