export default {
  'ID_POFTIGO_FOOD_LABEL': 'mâncare',
  'ID_POFTIGO_FOOD_LABEL_ESC': 'mancare',
  'ID_POFTIGO_DRINKS_LABEL': 'băuturi',
  'ID_POFTIGO_DRINKS_LABEL_ESC': 'bauturi',
  'ID_POFTIGO_SEARCH_MENU_PLACEHOLDER': 'De ce ai poftă azi?',
  'ID_POFTIGO_INGREDIENTS': 'Ingrediente',
  'ID_POFTIGO_LABELS': 'Etichete',
  'ID_POFTIGO_ALLERGENS': 'Alergeni',
  'ID_POFTIGO_DOWNLOAD_APP_TEXT': 'Scanează codul QR in aplicație și comandă',
  'ID_POFTIGO_DOWNLOAD_APP_TITLE': 'Descarcă aplicația Poftigo',
  'ID_POFTIGO_POPUP_DISMISS_BUTTON': 'Nu mulțumesc, continui sa văd meniul',
  'ID_POFTIGO_SEARCH_NO_RESULTS': 'Hmm... Nu exista niciun preparat care sa corespunda cautarii.',
  'ID_POFTIGO_SEARCH_NO_RESULTS_CTA': 'Afiseaza tot meniul',
  'ID_POFTIGO_DOWNLOAD_APP_TEXT_SELF1': '1. Descarcă aplicația Poftigo',
  'ID_POFTIGO_DOWNLOAD_APP_TEXT_SELF2': '2. Scanează codul QR in aplicație, comandă și plătește',
  'ID_POFTIGO_ORDER_INVITATION':'Vrei să comanzi?',
  'ID_POFTIGO_TABLE_LABEL':'Masa',
  'ID_POFTIGO_CALL_WAITER':'Cheamă ospătar',
  'ID_POFTIGO_COOKIE_MESSAGE':'Acest site stochează cookies... nom nom nom... va stoca în continuare cookies',
  'ID_POFTIGO_COOKIE_ACCEPT':'De acord!',
  'ID_POFTIGO_COOKIE_MORE':'Afla mai multe',
  'ID_POFTIGO_COOKIE_MORE_LINK':'aici',
  'ID_POFTIGO_FEEDBACK':'Acordă feedback',
  'ID_POFTIGO_SUBMIT':'Trimite',
  'ID_POFTIGO_CALL_WAITER_SUCCESS': 'Ospătar chemat',
  'ID_POFTIGO_FEEDBACK_SECTION_FOOD': 'Mâncare',
  'ID_POFTIGO_FEEDBACK_SECTION_RESTAURANT': 'Restaurant',
  'ID_POFTIGO_FEEDBACK_SECTION_WAITERS': 'Ospătari',
  'ID_POFTIGO_FEEDBACK_TEXT1': 'Cum ți s-a părut experiența la ',
  'ID_POFTIGO_FEEDBACK_TEXT2': ' Scrie-ne părerea ta',
  'ID_POFTIGO_FEEDBACK_COMMENTS_PLACEHOLDER': 'Comentarii',
  'ID_POFTIGO_FEEDBACK_SUBMIT_SUCCESS': 'Mulțumim! Feedback-ul a fost trimis!',
  'ID_POFTIGO_FEEDBACK_SUCCESS':'Feedback trimis',
  'ID_POFTIGO_BROWSE_MENU': 'Vezi meniu',
  'ID_POFTIGO_FEEDBACK_SECTION_WAITER': 'Servire',
  'ID_POFTIGO_TOBACCO_LABEL': 'tutun',
  'ID_POFTIGO_TOBACCO_LABEL_ESC': 'tutun',
  'ID_POFTIGO_MENU_ITEMS': 'Preparate',
  'ID_POFTIGO_OR': 'sau',
  'ID_POFTIGO_CALL_WAITER_TEXT': 'Opțional, poți scrie un mesaj',
  'ID_POFTIGO_NOTIFICATION_CALL_WAITER_SUCCESS': 'Am trimis notificarea către ospătar!',
  'ID_POFTIGO_SUBMIT_CALL_WAITER':'Cheamă',
  'ID_POFTIGO_REQUEST_PAYMENT':'Cere nota',
  'ID_POFTIGO_FEEDBACK_LABEL':'Feedback',
  'ID_POFTIGO_REQUEST_PAYMENT_TEXT1':'Cum plătești?',
  'ID_POFTIGO_REQUEST_PAYMENT_TEXT2':'Ai dori să adaugi un bacșiș?',
  'ID_POFTIGO_REQUEST_PAYMENT_OPTION1':'Numerar',
  'ID_POFTIGO_REQUEST_PAYMENT_OPTION2':'Card',
  'ID_POFTIGO_REQUEST_PAYMENT_OPTION3':'Alta metoda',
  'ID_POFTIGO_REQUEST_PAYMENT_TIPS_PH':'Introdu suma',
  'ID_POFTIGO_ERROR_MESSAGE':'A aparut o eroare',
  'ID_POFTIGO_REQUEST_PAYMENT_SUCCESS':'Notă cerută',
};