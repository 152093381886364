import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiHelpers, useWindowDimensions } from '../helpers';
import { Spin, Layout, Row, Col, Drawer, Skeleton, Modal } from 'antd';
import MenuCategory from './MenuCategory';

import { ReactComponent as LogoFull } from '../assets/logo-poftigo-full.svg';
import { ReactComponent as LogoMark } from '../assets/svg/logomark-poftigo.svg';
import MenuCategoryNav from './MenuCategoryNav';
import MenuItem from './MenuItem';


import { Decryptor, normalizeString } from '../helpers';
import { firebaseConfig } from '../config';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useTranslation } from 'react-i18next';
import i18n from '../config/i18n';
import Footer from './Footer';
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const { Footer: AntdFooter, Content } = Layout;

const LocationDetail = () => {
    let { locationId } = useParams();
    let location = useLocation();
    let url = location.pathname;
    const { t } = useTranslation();
    const navigate = useNavigate();
    //    const { data: restaurantDetails, isLoading } = useRestaurantDetailsPublicById(locationId);
    const [restaurantDetails, setRestaurantDetails] = useState({});
    const [restaurantDetailsFilter, setRestaurantDetailsFilter] = useState({});
    const [menuCategories, setMenuCategories] = useState([]);
    const [menuCategoriesFilter, setMenuCategoriesFilter] = useState([]);
    const [categorySelected, setCategorySelected] = useState({
        active: false,
        categoryId: '',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [foundMenuItems, setFoundMenuItems] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState({});
    const [isFilter, setIsFilter] = useState({});
    const [selectedTag, setSelectedTag] = useState(t('ID_POFTIGO_FOOD_LABEL_ESC'));
    const { height, width } = useWindowDimensions();

    const [drawerProps, setDrawerProps] = useState({
        visible: false,
        placement: 'bottom',
    });
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setIsLoading(true);

        let params = new URLSearchParams(location.search);
        const hash = /[^/]*$/.exec(locationId)[0];
        let restaurantId = '';
        if (Decryptor(hash)) {
            restaurantId = JSON.parse(Decryptor(hash)).RestaurantToken;
            ApiHelpers.getRestaurantDetails(restaurantId, i18n.language).then(
                (response) => {
                    if (!response.data.message) {
                        document.title = response.data.restaurantName + ' - Poftigo';
                        if (response.data.subscriptionPlan === 'Full') setDrawerProps({ ...drawerProps, visible: true });
                        // Log event in Analytics
                        setRestaurantDetails(response.data);
                        setRestaurantDetailsFilter(response.data);

                        // console.log(
                        //     i18n.language,
                        //     'Filter after',
                        //     t('ID_POFTIGO_FOOD_LABEL_ESC'),
                        //     response.data.menu[0].category.filter((d) => d.tag === t('ID_POFTIGO_FOOD_LABEL_ESC')),
                        //     response.data.menu[0].category
                        // );
                        setMenuCategories(response.data.menu[0].category.filter((d) => d.tag === t('ID_POFTIGO_FOOD_LABEL_ESC')));
                        setMenuCategoriesFilter(response.data.menu[0].category);
                        if (!params.get('tag')) navigate(url + '?tag=' + t('ID_POFTIGO_FOOD_LABEL_ESC'));
                        setIsLoading(false);
                    } else {
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [locationId, i18n.language]);

    const showModal = (menuItem) => {
        setIsModalVisible(true);
        // Log event in Analytics
        logEvent(analytics, 'select_content', {
            content_type: 'menuItem',
            item_id: menuItem.menuItemToken,
        });
        setSelectedMenuItem(menuItem);
    };

    const onClose = () => {
        logEvent(analytics, 'popup_closed');
        setDrawerProps({ ...drawerProps, visible: false });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        const qsCategoryTag = searchParams.get('tag');
        if (qsCategoryTag) {
            filterByCategoryTag(qsCategoryTag);
        }
    }, [menuCategoriesFilter, searchParams]);

    const filterByCategoryTag = (tag) => {
        let params = new URLSearchParams(location.search);
        if (params.get('tag') !== tag) navigate(url + '?tag=' + tag);
        logEvent(analytics, 'select_content', {
            content_type: 'menuCategoryTag',
            item_id: tag,
            page_title: restaurantDetails.uniqueIdentificationToken,
        });
        setKeyword('');
        setIsFilter({});
        setCategorySelected({
            active: false,
            categoryId: '',
        });
        setIsSearch(false);
        setFoundMenuItems([]);
        const filteredCategories = menuCategoriesFilter.filter((d) => d.tag === tag);

        setSelectedTag(tag);
        setMenuCategories([...filteredCategories]);
    };

    const handleSearch = (keyword) => {
        setIsLoading(true);
        setKeyword(keyword);
        setCategorySelected({
            active: false,
            categoryId: '',
        });
        setIsSearch(true);
        const lowCaseKeyword = normalizeString(keyword);
        let categoryItem = [];
        let foundMenuItems = [];
        // Log event in Analytics
        if (keyword) {
            logEvent(analytics, 'search', {
                search_term: keyword,
                page_title: restaurantDetails.uniqueIdentificationToken,
            });
            // Search and filter menu categories
            let newMenuCategories = menuCategoriesFilter.filter((category) => {
                let categoryItem = [];
                if (category.item) {
                    const filteredItems = category.item.filter((item) => {
                        if (item.title && normalizeString(item.title).indexOf(lowCaseKeyword) > -1) {
                            return true;
                        }
                        return false;
                    });
                    foundMenuItems = [...foundMenuItems, ...filteredItems];
                    if (filteredItems.length > 0) return true;
                }
                if (normalizeString(category.categoryName).indexOf(lowCaseKeyword) > -1)
                    // If found in menu category name
                    return true;
                return false;
            });

            setFoundMenuItems(foundMenuItems);
            setMenuCategories(newMenuCategories);
            setIsLoading(false);
        } else {
            filterByCategoryTag(t('ID_POFTIGO_FOOD_LABEL_ESC'));
            setIsLoading(false);
            setIsSearch(false);
        }
    };

    const handleClickCategory = (categoryToken, categoryName) => {
        window.scrollTo(0, 0);
        navigate(url, { state: categoryName });
        if (categoryName) {
            setCategorySelected({
                active: true,
                categoryId: categoryName,
            });
            let foundMenuItems = [];
            menuCategoriesFilter.map((category) => {
                if (category.categoryName === categoryName)
                    if (category.item) {
                        // Log event in Analytics
                        logEvent(analytics, 'select_content', {
                            content_type: 'menuCategory',
                            item_id: categoryToken,
                        });

                        foundMenuItems = category.item.map((item) => {
                            return item;
                        });
                    }
            });
            setFoundMenuItems(foundMenuItems);
        } else {
            setCategorySelected({
                active: false,
                categoryId: '',
            });
            handleSearch(keyword);
        }
    };

    const refreshPage = () => {
        setIsLoading(true);
        navigate(url + '?tag=' + t('ID_POFTIGO_FOOD_LABEL_ESC'));
        setSelectedTag(t('ID_POFTIGO_FOOD_LABEL_ESC'));

        if (restaurantDetails.subscriptionPlan === 'Full') setDrawerProps({ ...drawerProps, visible: true });
        setCategorySelected({
            active: false,
            categoryId: '',
        });
        handleSearch('');
        setTimeout(function () {
            setIsLoading(false);
        }, 100);
    };

    const handleClickStore = (store) => {
        // Log event in Analytics
        logEvent(analytics, 'select_content', {
            content_type: 'button',
            item_id: store,
        });
    };

    const handleChangeLanguage = (e, langCode) => {
        e.preventDefault();
        // setter
        localStorage.setItem('language', langCode);
        i18n.changeLanguage(langCode);
        refreshPage();
    };

    const menuItemContent = (
        <>
            <div className="menuItemDetails">
                {selectedMenuItem.itemImagePath ? (
                    <img
                        src={ApiHelpers.getImageLink(selectedMenuItem.itemImagePath)}
                        alt={selectedMenuItem.title}
                        title={selectedMenuItem.title}
                        className="menuItemImage modal"
                    />
                ) : null}
                <Row gutter={[20, 20]} justify="space-between">
                    <Col span={18}>
                        {selectedMenuItem.itemDescription ? <p>{selectedMenuItem.itemDescription}</p> : null}

                        {selectedMenuItem.ingredients ? (
                            <>
                                <h2 className="sectionTitle firstItem">{t('ID_POFTIGO_INGREDIENTS')}</h2>
                                <div className="sectionTitleSeparator"></div>
                                <div>{selectedMenuItem.ingredients.replaceAll(',', ', ')}</div>
                            </>
                        ) : null}
                        {selectedMenuItem.allergens ? (
                            <>
                                <h2 className="sectionTitle">{t('ID_POFTIGO_ALLERGENS')}</h2>
                                <div className="sectionTitleSeparator"></div>
                                {selectedMenuItem.allergens ? selectedMenuItem.allergens : null}
                            </>
                        ) : null}
                    </Col>
                    <Col span={6} align="right">
                        <p className="price">{selectedMenuItem.price} lei</p>
                        {selectedMenuItem.weight ? <span>({selectedMenuItem.weight})</span> : null}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {selectedMenuItem.groups
                            ? selectedMenuItem.groups.map((group) => {
                                  return (
                                      <div key={group.menuItemGroupToken}>
                                          <h3 className="auxiliaryGroup">{group.displayGroupName}</h3>
                                          {group.items
                                              ? group.items.map((item, index) => {
                                                    return (
                                                        <Row gutter={[20, 20]} justify="center" key={item.uniqueIdentificationToken}>
                                                            <Col span={18}>{item.menuItemName}</Col>
                                                            <Col span={6} align="right">
                                                                {item.price > 0 ? <span>+ {item.price} lei</span> : null}
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                              : null}
                                      </div>
                                  );
                              })
                            : null}
                    </Col>
                </Row>
            </div>
        </>
    );

    return (
        <Layout>
            <Content className="restaurantContainer">
                {width > 600 ? (
                    <Modal
                        title={selectedMenuItem.title}
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        closable={true}
                        height={window.innerHeight - 200}
                        footer={null}
                        maskClosable={true}>
                        {menuItemContent}
                    </Modal>
                ) : (
                    <Drawer
                        title={selectedMenuItem.title}
                        placement="bottom"
                        closable={true}
                        onClose={handleCancel}
                        visible={isModalVisible}
                        height={window.innerHeight - 200}>
                        {menuItemContent}
                    </Drawer>
                )}

                {restaurantDetails ? (
                    restaurantDetails.statusCode !== 0 ? (
                        <>
                            <Row>
                                <Col
                                    xs={{ span: 22, offset: 1 }}
                                    md={{ span: 20, offset: 2 }}
                                    lg={{ span: 18, offset: 3 }}
                                    xl={{ span: 18, offset: 3 }}
                                    xxl={{ span: 16, offset: 4 }}
                                    className="contentRow">
                                    {isLoading ? (
                                        <>
                                            <div className="preloaderLogo">
                                                <LogoFull />
                                            </div>
                                            <Skeleton active className="loader" />
                                        </>
                                    ) : (
                                        <>
                                            {menuCategories.length > 0 ? (
                                                <Row className="restaurantMenuContainer headline" justify="space-between">
                                                    <Col>
                                                        <div className="menuCategoryTagContainer">
                                                            <a
                                                                className={selectedTag === t('ID_POFTIGO_FOOD_LABEL_ESC') ? 'active' : null}
                                                                onClick={() => filterByCategoryTag(t('ID_POFTIGO_FOOD_LABEL_ESC'))}>
                                                                {t('ID_POFTIGO_FOOD_LABEL')}
                                                            </a>
                                                            <a
                                                                className={selectedTag === t('ID_POFTIGO_DRINKS_LABEL_ESC') ? 'active' : null}
                                                                onClick={() => filterByCategoryTag(t('ID_POFTIGO_DRINKS_LABEL_ESC'))}>
                                                                {t('ID_POFTIGO_DRINKS_LABEL')}
                                                            </a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ) : null}

                                            {categorySelected.active !== true ? (
                                                <Row className="restaurantMenuContainer" gutter={[30, 30]}>
                                                    {menuCategories &&
                                                        menuCategories.map((menuCategory, index) => {
                                                            return (
                                                                <MenuCategory
                                                                    menuCategory={menuCategory}
                                                                    key={menuCategory.categoryName}
                                                                    handleClickCategory={handleClickCategory}
                                                                />
                                                            );
                                                        })}
                                                </Row>
                                            ) : (
                                                <MenuCategoryNav
                                                    menuCategory={categorySelected.categoryId}
                                                    handleClickCategory={handleClickCategory}
                                                />
                                            )}

                                            <Row className="restaurantMenuContainer" gutter={[8, 20]}>
                                                {menuCategories.length > 0 || foundMenuItems.length > 0 ? (
                                                    <>
                                                        {categorySelected.active !== true && isSearch === true ? (
                                                            <Col span={24}>
                                                                <h2 className="subTitle">{t('ID_POFTIGO_MENU_ITEMS')}</h2>
                                                            </Col>
                                                        ) : null}
                                                        <Col span={24}>
                                                            <Row justify="space-between">
                                                                {foundMenuItems.map((d, index) => {
                                                                    return <MenuItem menuItem={d} key={'menuItem' + index} showModal={showModal} />;
                                                                })}
                                                            </Row>
                                                        </Col>
                                                    </>
                                                ) : (
                                                    <p className="infoMessage">
                                                        {t('ID_POFTIGO_SEARCH_NO_RESULTS')}.{' '}
                                                        <a onClick={() => handleSearch('')}>{t('ID_POFTIGO_SEARCH_NO_RESULTS_CTA')}</a>
                                                    </p>
                                                )}
                                            </Row>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <div>Not found</div>
                    )
                ) : (
                    <Spin />
                )}
            </Content>
            <AntdFooter>
                <Footer branding={true} />
            </AntdFooter>
        </Layout>
    );
};

export default LocationDetail;
