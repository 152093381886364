import React from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LeftCircleFilled } from '@ant-design/icons';

const MenuCategoryNav = (props) => {
    const { menuCategory, handleClickCategory } = props;
    const navigate = useNavigate();

    return (
        <Row>
            <Col>
                <LeftCircleFilled
                    className="navCategoryIcon"
                    onClick={() => {
                        handleClickCategory();
                        navigate(-1);
                    }}
                />
            </Col>
            <Col>
                <h2 className="subTitle navCategory">{menuCategory}</h2>
            </Col>
        </Row>
    );
};

export default MenuCategoryNav;
