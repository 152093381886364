import * as ApiHelpers from './ApiHelpers';
import Decryptor from './Decryptor';
import useWindowDimensions from './useWindowDimensions';
import useTimeout from './useTimeout';

const normalizeString = (string) => {
    return string
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
};

export { Decryptor, ApiHelpers, normalizeString, useWindowDimensions, useTimeout };
