import { bacchusAPIEndpoint, bacchusAPIUploadsPath } from '../config';
import axios from 'axios';
import i18n from '../config/i18n';

const languageCodeTranslation = (langCode) => {
    return langCode.replace('-', '_');
};

// Uploads and media

export const getImageLink = (imagePath) => {
    return bacchusAPIUploadsPath + imagePath.replace('Images\\', '');
};

export const getRestaurantDetails = (uniqueIdentificationToken, langCode = i18n.language) => {
    return axios(bacchusAPIEndpoint + '/api/menu/' + uniqueIdentificationToken + '/' + languageCodeTranslation(langCode), {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const callWaiter = (restaurantToken, tableToken, message = '') => {
    return axios(bacchusAPIEndpoint + '/api/web/callWaiter', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            tableSessionToken: '',
            uuid: '',
            restaurantToken: restaurantToken,
            tableToken: tableToken,
            message: message,
        },
    });
};

export const submitFeedback = (restaurantToken, feedbackMessage) => {
    return axios(bacchusAPIEndpoint + '/api/feedback/restaurant/' + restaurantToken, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...feedbackMessage,
        },
    });
};

export const getRestaurantTable = (hash) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/table/getRestaurantTable/' + hash, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const requestBill = (restaurantToken, tableToken, paymentMethod, tip = null) => {
    return axios(bacchusAPIEndpoint + '/api/web/requestBill', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            tableSessionToken: '',
            uuid: '',
            selfOnly: 'false',
            paymentMethod: paymentMethod,
            companyCode: '',
            paymentMethodDetails: '',
            restaurantToken: restaurantToken,
            tableToken: tableToken,
            tip: tip,
        },
    });
};
