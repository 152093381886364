export default {
    'ID_POFTIGO_FOOD_LABEL': 'food',
    'ID_POFTIGO_FOOD_LABEL_ESC': 'food',
    'ID_POFTIGO_DRINKS_LABEL': 'beverages',
    'ID_POFTIGO_DRINKS_LABEL_ESC': 'beverages',
    'ID_POFTIGO_SEARCH_MENU_PLACEHOLDER': 'What do you crave today?',
    'ID_POFTIGO_INGREDIENTS': 'Ingredients',
    'ID_POFTIGO_LABELS': 'Tags',
    'ID_POFTIGO_ALLERGENS': 'Allergens',
    'ID_POFTIGO_DOWNLOAD_APP_TEXT': 'Order now and request the bill with the Poftigo App',
    'ID_POFTIGO_DOWNLOAD_APP_TITLE': 'Download the Poftigo App',
    'ID_POFTIGO_POPUP_DISMISS_BUTTON': 'No thanks, I will just browse the menu',
    'ID_POFTIGO_SEARCH_NO_RESULTS': 'Hmm... Nothing found matching the keyword',
    'ID_POFTIGO_SEARCH_NO_RESULTS_CTA': 'Browse the menu',
    'ID_POFTIGO_DOWNLOAD_APP_TEXT_SELF1': '1. Download the Poftigo app',
    'ID_POFTIGO_DOWNLOAD_APP_TEXT_SELF2': '2. Scan the QR Code inside the app, order and pay',
    'ID_POFTIGO_ORDER_INVITATION':'Do you want to order?',
    'ID_POFTIGO_TABLE_LABEL':'Table',
    'ID_POFTIGO_CALL_WAITER':'Call waiter',
    'ID_POFTIGO_COOKIE_MESSAGE':'This website stores cookies... nom nom nom... will store cookies',
    'ID_POFTIGO_COOKIE_ACCEPT':'I agree!',
    'ID_POFTIGO_COOKIE_MORE':'Find out more',
    'ID_POFTIGO_COOKIE_MORE_LINK':'here',
    'ID_POFTIGO_FEEDBACK':'Send feedback',
    'ID_POFTIGO_SUBMIT':'Send',
    'ID_POFTIGO_CALL_WAITER_SUCCESS': 'Waiter called',
    'ID_POFTIGO_FEEDBACK_SECTION_FOOD': 'Food',
    'ID_POFTIGO_FEEDBACK_SECTION_RESTAURANT': 'Restaurant',
    'ID_POFTIGO_FEEDBACK_SECTION_WAITERS': 'Waiters',
    'ID_POFTIGO_FEEDBACK_TEXT1': 'How was your experience at ',
    'ID_POFTIGO_FEEDBACK_TEXT2': ' Send us your feedback',
    'ID_POFTIGO_FEEDBACK_COMMENTS_PLACEHOLDER': 'Comments',
    'ID_POFTIGO_FEEDBACK_SUBMIT_SUCCESS': 'Thank you! Your feedback was submitted!',
    'ID_POFTIGO_FEEDBACK_SUCCESS':'Feedback submitted!',
    'ID_POFTIGO_BROWSE_MENU': 'Browse menu',
    'ID_POFTIGO_FEEDBACK_SECTION_WAITER': 'Service',
    'ID_POFTIGO_TOBACCO_LABEL': 'tobacco',
    'ID_POFTIGO_TOBACCO_LABEL_ESC': 'tobacco',
    'ID_POFTIGO_MENU_ITEMS': 'Items',
    'ID_POFTIGO_OR': 'or',
    'ID_POFTIGO_CALL_WAITER_TEXT': 'Write a short message (optional)',
    'ID_POFTIGO_NOTIFICATION_CALL_WAITER_SUCCESS': 'Notification was sent to waiter!',
    'ID_POFTIGO_SUBMIT_CALL_WAITER':'Call',
    'ID_POFTIGO_REQUEST_PAYMENT':'Request bill',
    'ID_POFTIGO_FEEDBACK_LABEL':'Feedback',
    'ID_POFTIGO_REQUEST_PAYMENT_TEXT1':'Payment method',
    'ID_POFTIGO_REQUEST_PAYMENT_TEXT2':'Want to leave tips',
    'ID_POFTIGO_REQUEST_PAYMENT_OPTION1':'Cash',
    'ID_POFTIGO_REQUEST_PAYMENT_OPTION2':'Card',
    'ID_POFTIGO_REQUEST_PAYMENT_OPTION3':'Other',
    'ID_POFTIGO_REQUEST_PAYMENT_TIPS_PH':'Enter amount',
    'ID_POFTIGO_ERROR_MESSAGE':'An error has occurred',
    'ID_POFTIGO_REQUEST_PAYMENT_SUCCESS':'Order requested',
};